import React from 'react';
import AboutUs from '../../images/about_us.svg';
import TitleHeading from '../../components/TitleHeading';

function About() {
  return (
    <div id="section-about" className="section-about w-100 d-flex align-items-center">
      <div className="section-about-container container d-flex justify-content-between my-4">
        <img alt="AboutUs" src={AboutUs} className="section-about-img align-self-center" />
        <div className="align-self-center">
          <TitleHeading heading="ABOUT US" />
          <p>Life for The People and The Planet</p>
          <p>At IZIFILL, we believe there is a better way to drink water.
          Water is a basic need for us, but the current water distribution system forced us to use a single used plastic bottle,
          that end up polluting our planet.
          More and more people care about this problem, but we don’t have a lot of choices,
            so we think that the new way to drink water is necessary.</p>
          <p>We believe, with the support from all those who care, backed with technology,
          we can take part to create a solution that provides people with healthy
          and fresh water without polluting our planet through IZIFILL Water Station.
            Together with you, we believe we can make it happen.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
