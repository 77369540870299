import React from 'react';
import YellowWave from '../images/yellow_wave.svg';

const TitleHeading = props => {
  return (
    <div className={`title-heading ${props.className}`}>
        <h1 className="w-100 wider bigger">{props.heading}</h1>
        <div className="title-heading-img-container">
            <img alt="title heading" className="title-heading-img" src={YellowWave}/>
        </div>   
    </div>    
  );
}

export default TitleHeading;